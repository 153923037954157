@value unit, secondary-color, font-size-smaller, line-color from '../../../ring-globals.css';

.heading.heading {
  margin: 0;

  margin-bottom: unit;
  padding: 0;

  font: inherit;
  font-weight: bold;
}

.filter {
  display: flex;

  align-items: start;
  flex-direction: column;

  margin-bottom: calc(unit * 2);
}

.changesTypeFilter {
  margin-bottom: calc(unit * 2);
}

.changesTypeFilter,
.changesFilter {
  margin-right: calc(unit * 2);

  &:last-child {
    margin-right: 0;
  }
}

.showAllButton {
  margin-top: unit;
}

.emptyList {
  color: secondary-color;

  font-size: font-size-smaller;
}

@value unit from '../../../../ring-globals.css';

.agent {
  margin: 0 calc(unit * -6) calc(unit / 2) calc(-11 * unit);
  grid-column: start / end;
}

.agent-placeholder {
  margin: 0 calc(unit * -6) calc(unit / 2) calc(-11 * unit);
  grid-column: start / end;
}

@value unit from '../../../ring-globals.css';

.tree {
  margin-top: calc(unit * 2);
}

.investigationMuteButtons,
.expandAll {
  margin-right: calc(unit * 1.5);
}

@value contentWidth, unit, font, secondary-color, font-size-smaller, line-height-lowest from '../../../../ring-globals.css';

.header {
  max-width: contentWidth;
  margin-bottom: calc(unit * 2);
}

.heading {
  composes: arrowWrapper from '../../../ProjectOrBuildTypeIcon/ProjectOrBuildTypeIcon.css';
}

.heading.heading {
  margin: 0;
}

.heading.projectArchived .title {
  color: secondary-color;

  font-weight: normal;
}

.star {
  margin-left: -2px;

  vertical-align: 3px;
}

.branchFilter {
  flex: 0 0 auto;

  font-size: font-size-smaller;

  font-weight: normal;
  line-height: line-height-lowest;
}

.links {
  display: flex;
  align-items: baseline;
}

.linksSequenceLoader {
  display: flex;
  flex-direction: row-reverse;

  & > * {
    margin-right: unit;
  }

  & > *:first-child {
    margin-right: 0;
  }
}

.breadcrumbs {
  flex-grow: 1;
}

.breadcrumbsLink {
  font-size: font-size-smaller;
  line-height: 24px;
}

.subHeader {
  display: flex;
  align-items: baseline;

  width: 100%;
  margin-top: unit;
}

.branchFilter,
.createProject {
  margin-right: unit;
}

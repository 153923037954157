@value unit, popup-shadow-color, line-color, main-color, icon-secondary-color from '../../../../../../../ring-globals.css';
@value focusableWrapper from '../../../../../../common/ListItem/ListItem.css';
@value statusCol from './TestItemAdvanced/TestItemAdvanced.css';

.panel {
  z-index: 1;

  margin: 1px calc((unit * -4)) 0;
  padding: 0 calc((unit * 4)) 0;

  box-shadow: 0 0 0 1px line-color, 0 2px 8px popup-shadow-color;
}

.button {
  composes: focusableWrapper;
}

.arrow {
  position: absolute;

  top: 8px;
  right: calc(unit * -4 + 10px);
  left: auto;

  padding: 0;

  transition: opacity ease;

  pointer-events: none;

  opacity: 0;
  color: icon-secondary-color;

  line-height: normal;

  @nest .button:hover &, .button:focus &, .button[data-focus-visible-added] & {
    transition: none;

    opacity: 1;
  }

  @nest .button:active & {
    transition: none;

    opacity: 1;
    color: main-color;
  }
}

.groupArrow {
  position: absolute;

  top: unit;
  right: auto;
  left: calc(unit * -4 + 10px);

  padding: 0;

  transition: opacity ease;

  pointer-events: none;

  color: icon-secondary-color;

  line-height: normal;
}

.allInvocationsLink {
  padding: unit 0 calc(unit * 2) calc(unit * 3);
}

.expandable {
  cursor: pointer;
}

.invocationRow {
  margin-left: calc(unit * 2);

  & .statusCol {
    flex: 0 0 144px;
  }
}

.detailsAdvancedRowShift {
  padding-left: calc(unit * 2 - 2px);
}

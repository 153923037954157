@value unit, ellipsis from '../../../../../../../../ring-globals.css';
@value focusableWrapper from '../../../../../../../common/ListItem/ListItem.css';

.item {
  composes: focusableWrapper;

  position: relative;

  display: block;

  margin: 1px calc(unit * -4) 0;
}

.copyTestNameIcon {
  opacity: 0;

  &:focus {
    opacity: 1;
  }
}

.row {
  display: flex;
  align-items: baseline;
  flex-direction: row;

  max-height: calc(unit * 4);

  padding: 0;

  & > * {
    margin-top: 0;
  }

  &:hover .copyTestNameIcon {
    opacity: 1;
  }
}

.advancedRow {
  line-height: 32px;
}

.actions {
  width: unset;
  margin-left: auto;
  padding: 0;
  padding-left: unit;
}

.advancedRow .actionsCol {
  display: flex;

  flex: 0 0 auto;

  justify-content: flex-end;

  width: calc(unit * 4);

  padding: 0;
}

.flakyLabel,
.testMetadataIcon {
  margin-left: unit;
  padding: 0;
}

.testMutes,
.testInvestigations {
  flex: 0 0 auto;

  padding: 0;
}

.testMutesIcon,
.testInvestigationsIcon {
  margin-right: calc(unit / 2);
}

.row .checkbox {
  padding: 0;

  white-space: nowrap;
}

.statusCol {
  display: flex;
  overflow: hidden;
  flex: 0 0 168px;
}

.testCol {
  display: flex;
  overflow: hidden;
  align-items: baseline;
  flex: 1 1 auto;
}

.durationCol {
  flex: 0 0 136px;

  text-align: right;
}

.orderCol {
  flex: 0 0 60px;

  padding: 0;

  text-align: right;
}

.statusCol,
.testCol,
.durationCol {
  margin: 0;
  padding: 0;
  padding-right: calc(unit * 2);
}

.name {
  composes: ellipsis;

  padding: 0;
}

@value unit, error-color, success-color from '../../../../ring-globals.css';

.line {
  margin-bottom: calc(1.5 * unit);
}

.status-up {
  color: success-color;
}

.status-down {
  color: error-color;
}

.separate {
  text-transform: capitalize;
}

.commentWrapper {
  font-size: calc(1.5 * unit);
  line-height: calc(2 * unit);
}

.comment {
  display: inline-block;
}

.switch-time {
  font-weight: bold;
}

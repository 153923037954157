@value unit, icon-color, main-color, main-hover-color, ease from '../../ring-globals.css';
@value remove from '@jetbrains/ring-ui/components/tag/tag.css';

.tag {
  vertical-align: baseline;

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tag + .remove {
  top: 2px;
}

.icon {
  margin-right: calc(unit / 2);

  color: icon-color;
}

.multiple {
  position: relative;
  z-index: 1;

  display: inline-flex;
}

.selected {
  &,
  &::before {
    transition: background-color ease;

    background-color: main-color;
  }

  &,
  &:hover {
    color: var(--ring-white-text-color);
  }

  &:hover,
  &:focus,
  &[data-focus-visible-added] {
    &,
    &::before {
      transition: none;

      background-color: main-hover-color;

      box-shadow: none;
    }
  }
}

.linkInner {
  max-width: 100%;
}

@value unit, line-height-lower, font-size, error-color from '../../../../ring-globals.css';

.input {
  margin-bottom: calc(unit * 4);
}

.error {
  margin-top: calc(unit * 2);

  color: error-color;
}

@value unit, secondary-color, borders-color, font-smaller-lower, sidebar-background-color, content-background-color, line-color from '../../../ring-globals.css';

.container {
  min-width: calc(unit * 40);
  max-height: calc(unit * 50);
}

.popupContainer {
  max-height: calc(50vh - unit * 4);
}

.multiselect {
  background-color: content-background-color;
  box-shadow: inset 0 0 0 1px line-color;
}

.tree {
  height: 100%;
}

.treeContent {
  flex: 1 0 0;

  height: auto;
  margin-top: unit;
}

.searchWrapper {
  padding: 0 unit 0 calc(unit * 2);

  box-shadow: 0 1px borders-color;
}

.placeholder {
  composes: font-smaller-lower;

  margin: calc(unit * 2) 0 0 calc(unit * 4);

  color: secondary-color;
}

.icon {
  color: inherit;
}

.hint {
  composes: font-smaller-lower;

  box-sizing: border-box;

  min-height: calc(unit * 4);
  padding: unit calc(unit * 2);

  color: secondary-color;

  background-color: sidebar-background-color;
  box-shadow: inset 0 1px line-color;
}

.popup {
  z-index: 100;
}

.selectedNote {
  composes: font-smaller-lower;

  margin-top: unit;

  color: secondary-color;
}

.anchorText,
.anchorButton {
  max-width: 100%;

  text-align: start;
  white-space: nowrap;
}

.anchorButton {
  composes: buttonValue from '@jetbrains/ring-ui/components/select/select.css';
}

.anchorButtonOpen {
  composes: buttonValueOpen from '@jetbrains/ring-ui/components/select/select.css';
}

.name:not(:first-child) {
  max-width: calc(100% - unit * 3);
}

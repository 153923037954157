@value unit, line-color, secondary-color, font-size-smaller, resetButton from '../../../../../../ring-globals.css';

.tableHeader {
  display: flex;
  flex-direction: row;

  padding-bottom: unit;

  border-bottom: 1px solid line-color;
}

.sortableColumnTitle {
  composes: resetButton;

  cursor: pointer;
}

.statusCol {
  flex: 0 0 100px;
}

.durationCol {
  flex: 0 0 100px;

  text-align: right;
}

.buildCol {
  display: flex;
  flex: 1 1 auto;
}

.changesCol,
.agentCol {
  flex: 0 0 130px;
}

.buildStartDateCol {
  flex: 0 0 110px;

  margin-right: calc(unit * 4);

  text-align: right;
}

.statusCol,
.durationCol,
.buildCol,
.changesCol,
.agentCol {
  margin-right: calc(unit * 2);
}

.statusCol,
.durationCol,
.buildCol,
.changesCol,
.agentCol,
.buildStartDateCol {
  color: secondary-color;

  font-size: font-size-smaller;
}

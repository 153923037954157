@value unit, navigation-background-color from '../../ring-globals.css';
@value logoSize: calc(unit * 4);

.buildsTvMode {
  padding: calc(2 * unit) calc(4 * unit);
}

.title {
  padding-bottom: unit;
}

.titleText {
  display: inline-block;

  margin: 0;
}

.titleIcon {
  padding-left: 0;

  vertical-align: top;

  & svg {
    width: logoSize;
    height: logoSize;
  }
}

.hideDropDowns {
  /* stylelint-disable-next-line selector-max-specificity */
  & :global(.icon.toggle) {
    display: none;
  }
}

@value unit, breakpoint-middle, line-height-lower, font-size, line-color, popup-shadow-color, main-color, hover-background-color, icon-secondary-color, sidebar-background-color, line-height, border-radius-small from '../../../../ring-globals.css';
@value breakpointMiddle: breakpoint-middle;
@value breakpointLargest: 1600px;
@value lineHeightLower: line-height-lower;
@value build from '../Build/Build.css';

.buildContainer {
  --button-offset: calc(unit * 4);

  composes: hoverableWrapper from '../Build/BuildCheckbox/BuildCheckbox.css';
  composes: wrapper from '../../../common/StarBuild/StarBuild.css';

  display: contents;
}

.wrapped {
  --button-offset: calc(unit * 2);
}

.container {
  display: contents;
}

.wrappedContainer {
  composes: wrappedContainer from '../Build/Build.css';
}

.open {
  &::before {
    position: absolute;
    top: var(--absolute-child-top);
    right: calc(var(--button-offset) * -1);
    bottom: 1px;
    left: calc(var(--button-offset) * -1);

    content: '';
    pointer-events: none;

    background: var(--ring-content-background-color);

    box-shadow:
      0 0 0 1px line-color,
      0 2px 16px popup-shadow-color;
    grid-column: start / end;
    grid-row: var(--row) / span 4;
  }

  &.wrapped {
    --button-offset: calc(unit * 6);
  }

  &.nested {
    --button-offset: calc(unit * 4);

    &::before {
      border-radius: var(--ring-border-radius);
    }
  }

  & ul {
    margin: 0;
  }

  & :global(.summaryContainer) {
    padding: 0;
  }

  & ul li {
    padding: 0;

    line-height: lineHeightLower;

    &:not(:first-child) {
      margin-top: calc(unit * 3 - lineHeightLower);
    }
  }

  & :global(.buildResultsSummaryTable) {
    width: 100%;

    & > tbody > tr > td {
      padding: unit 0 calc(unit * 3) !important;

      /* stylelint-disable-next-line selector-max-specificity */
      &:nth-child(2) {
        width: 100%;
        max-width: 0;

        padding-left: calc(unit + 1px) !important;

        box-shadow: inset 0 1px line-color;
      }
    }
  }

  /* stylelint-disable-next-line selector-max-specificity */
  & td:nth-last-child(2) ul:global(.bsLinks) {
    width: 10vw;
    min-width: calc(0.1 * breakpointMiddle);
    max-width: calc(0.1 * breakpointLargest);
  }

  & :global(.header) {
    margin: 0;
    padding: 0;

    background: transparent;
  }

  /* stylelint-disable-next-line selector-max-specificity */
  & td:first-child > :global(.header) {
    display: none;
  }
}

.heading {
  display: contents;

  cursor: pointer;
}

.button {
  composes: beforeWrapper from '../../../common/StarBuild/StarBuild.css';

  content: '';

  grid-column: start / end;
  grid-row: var(--row) / span 3;
}

.button.button {
  top: calc(var(--absolute-child-top) - 1px);
  right: calc(var(--button-offset) * -1);
  left: calc(var(--button-offset) * -1);

  width: calc(100% + var(--button-offset) * 2);
}

.wrapped .button {
  top: var(--absolute-child-top);
  bottom: 1px;
}

.detailsWrapper {
  min-width: 0;
  margin: 0 calc(unit * -4);
  padding: 0 calc(unit * 4);
  grid-column: start / end;
}

.wrapped .detailsWrapper {
  margin: 0 calc(unit * -2);
  padding: 0 calc(unit * 2);
}

.queued .button,
.queued .detailsWrapper {
  background-color: sidebar-background-color;
}

.button:focus,
.button[data-focus-visible-added] {
  outline: none;
  box-shadow: inset 2px 0 main-color;
}

.inHierarchy .button,
.inHierarchy.open::before {
  left: calc(var(--button-offset) * -1 + var(--offset));
}

.inHierarchy .button {
  width: calc(100% + var(--button-offset) * 2 - var(--offset));
}

.nested.open .button {
  border-top-left-radius: var(--ring-border-radius);
  border-top-right-radius: var(--ring-border-radius);
}

.inHierarchy .detailsWrapper {
  margin-left: calc(unit * -4 + var(--offset));
}

.wrapped.inHierarchy .detailsWrapper {
  margin-left: calc(unit * -2 + var(--offset));
}

/* stylelint-disable-next-line selector-max-specificity */
.closed:hover .button,
.closed .button:focus,
.closed .button[data-focus-visible-added] {
  background-color: hover-background-color;
}

/* stylelint-disable-next-line selector-max-specificity */
.wrappedContainer:first-child .button,
.wrappedContainer:first-child .open::before {
  border-top-left-radius: border-radius-small;
  border-top-right-radius: border-radius-small;
}

/* stylelint-disable-next-line selector-max-specificity */
.withQueued .wrappedContainer:first-child .button,
.withQueued .wrappedContainer:first-child .open::before {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* stylelint-disable-next-line selector-max-specificity, no-descending-specificity */
.wrappedContainer:last-child .open::before,
.wrappedContainer:last-child .buildContainer:not(.open) .button {
  border-bottom-right-radius: border-radius-small;
  border-bottom-left-radius: border-radius-small;
}

.arrow {
  position: absolute;

  grid-column: -2;
  grid-row: var(--row) / span 3;

  top: calc(50% - 11px + var(--absolute-child-top));
  right: -15px;
  left: auto;

  padding: 0;

  pointer-events: none;

  color: transparent;

  line-height: normal;

  @nest .buildContainer:hover &,
  .button:focus ~ &,
  .button[data-focus-visible-added] ~ & {
    color: icon-secondary-color;
  }

  @nest .button:active ~ & {
    transition: none;

    opacity: 1;
    color: main-color;
  }
}

.content {
  overflow: hidden;

  width: 100%;

  margin: 0 calc(unit * -4);
  padding: 0 calc(unit * 4);
}

.tabs {
  overflow: hidden;

  margin: -1px calc(unit * -6) calc(unit * 2);
  padding: 7px calc(unit * 6) 9px;

  background-color: var(--ring-sidebar-background-color);

  font-size: var(--ring-font-size-smaller);

  line-height: var(--ring-line-height-lowest);
}

.nested .tabs {
  margin-right: calc(unit * -4);
  margin-left: calc(unit * -4);
}

.separator {
  position: relative;
  z-index: 1;

  margin-top: -1px;

  border-top: 1px solid line-color;
}

.loader {
  margin-bottom: calc(unit * 3);
}

.loaderPlaceholder {
  composes: loader;

  visibility: hidden;
}

.snapshotDependenciesChart {
  width: 40%;
}

@media (max-width: 1200px) {
  .snapshotDependenciesChart {
    width: 60%;
  }
}

.queueInfoTitle {
  font-size: font-size;
}

.queueInfoWrapper {
  display: flex;

  flex-wrap: wrap;

  font-size: font-size;

  line-height: line-height !important;

  & .queueInfo {
    margin-right: calc(unit * 1.5);
  }
}

.customizedBuildNote {
  margin-bottom: calc(unit * 1.5);
}

.secondInfoGroup .row {
  max-width: 600px;
  margin-bottom: unit;

  &:last-child {
    margin-bottom: calc(unit * 4);
  }
}

.emptyRow {
  grid-column: start / end;
}

.outerContainer {
  position: relative;
}

.innerContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

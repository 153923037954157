@value unit, line-height, secondary-color from '../../../ring-globals.css';

.buildTypeOverviewFooter {
  min-height: line-height;

  margin-top: calc(unit * 2);

  color: secondary-color;

  line-height: line-height;
}

.warning {
  margin-top: 0;
}

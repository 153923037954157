@value unit, warning-color from '../../../../ring-globals.css';

.assign-agents-button {
  margin-right: calc(unit);
}

.empty-message {
  margin-top: calc(2 * unit);
}

.heading.heading {
  margin: calc(2 * unit) 0;
}

.cloud-image {
  margin-bottom: calc(0.5 * unit);
}

.sorting-select {
  display: block;

  margin-top: calc(2 * unit);
}

.warning {
  color: warning-color;
}

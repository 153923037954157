@value unit, line-color, hover-background-color, fast-ease, ellipsis, font-smaller from '../../ring-globals.css';

.info {
  box-sizing: border-box;
  width: calc(unit * 58);
  margin: 0 calc(unit * -1);
  padding: unit calc(unit * 2);

  transition: background-color fast-ease;

  &:hover {
    transition: none;

    background-color: hover-background-color;
  }
}

.path {
  padding-bottom: unit;

  border-bottom: 1px solid line-color;
}

.row {
  display: flex;
  align-items: baseline;

  &:not(:first-child) {
    margin-top: calc(unit / 2);
  }

  & > * {
    flex-shrink: 0;

    &:not(:first-child) {
      margin-left: unit;
    }
  }
}

.header {
  composes: row;

  margin-bottom: unit;
}

.position {
  margin-right: calc(unit / 2);
}

.link,
.triggered {
  composes: ellipsis;

  flex: 1 0 0;

  min-width: 0;
}

.actions {
  margin: -3px calc(unit * -1);
}

.changes {
  composes: font-smaller;
}

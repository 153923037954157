@value unit, secondary-color, font-smaller-lower from '../../ring-globals.css';

.container {
  width: calc(unit * 50);
  min-height: calc(unit * 20);
  max-height: calc(100vh - unit * 24);
}

.tree {
  height: 100%;
}

.treeContent {
  flex: 1 0 0;

  height: auto;
}

.search {
  width: auto;
}

.placeholder {
  composes: font-smaller-lower;

  margin: calc(unit * 2) 0 0 calc(unit * 4);

  color: secondary-color;
}

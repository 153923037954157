@value secondary-color, link-color, link-hover-color, text-color, font-size-smaller from '../../../../../../../../ring-globals.css';

.link {
  display: inline;

  user-select: text;

  color: text-color;

  line-height: inherit;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;

    color: text-color;
  }

  & > * {
    border: 0 !important;
  }
}

.suite,
.package {
  color: secondary-color;

  font-size: font-size-smaller;
}

.suite,
.package,
.class {
  overflow: hidden;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

.newFailure .class,
.newFailure .separator,
.newFailure .name {
  font-weight: bold;
}

.fixed .class,
.fixed .separator,
.fixed .name {
  text-decoration: line-through;

  color: secondary-color;
}

.withInteractiveLinks .class {
  color: link-color;
}

.withInteractiveLinks .suite,
.withInteractiveLinks .package,
.withInteractiveLinks .class {
  &:hover,
  &:focus,
  &[data-focus-visible-added] {
    color: link-hover-color;
  }
}

@value unit from '../../../ring-globals.css';

.tree {
  margin-top: calc(unit * 2);
}

.expandAllButton,
.investigationMuteButtons {
  margin-right: unit;
}

.investigationMuteButtons {
  margin-left: unit;
}

@value unit, visuallyHidden, font-size, font-size-smaller, icon-color, secondary-color, line-color, main-color, content-background-color, hover-background-color, success-background-color, error-background-color, sidebar-background-color, error-color, icon-success-color, icon-error-color, icon-secondary-color, fast-ease, resetButton, borders-color, border-hover-color, text-color, border-radius from '../../../../../ring-globals.css';
@value success-progress-color from '../../../../common/BuildDuration/BuildDurationAnchor/BuildDurationAnchor.css';

.outerContainer {
  --tc-tail-width: calc(unit * 10);

  overflow: hidden;

  margin: calc(unit * 2) calc(unit * -4) 0;

  padding: 0 calc(unit * 4);
}

.container {
  position: relative;

  margin-top: calc(unit * 7);
}

.pannable {
  cursor: all-scroll;
}

.previewContainer {
  margin: 0 calc(unit * -4);
  padding: 0 calc(unit * 4);

  cursor: pointer;

  background-color: sidebar-background-color;

  box-shadow:
    0 1px 0 0 line-color inset,
    0 -1px 0 0 line-color inset;
}

.preview {
  position: relative;
}

.invisible {
  visibility: hidden;
}

.victoryContainer > svg {
  overflow: visible;
}

.barWrapper {
  position: absolute;

  display: flex;
}

.barDropdown {
  display: inline-flex;
}

.bar {
  composes: resetButton;

  position: relative;

  overflow: hidden;

  box-sizing: border-box;

  width: 100%;
  height: calc(unit * 2.5);
  padding-bottom: 2px;

  cursor: pointer;

  transition: opacity fast-ease, box-shadow fast-ease;

  border-radius: border-radius;
  outline: none;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';

    transition: border-color fast-ease;

    border: 2px solid transparent;

    border-radius: border-radius;
  }

  &:focus,
  &[data-focus-visible-added] {
    transition: none;

    box-shadow: 0 0 0 1px border-hover-color, 0 0 0 1px border-hover-color inset;

    &::after {
      transition: none;

      border-width: 1px;

      border-style: solid;

      border-color: border-hover-color;
    }
  }
}

.fade {
  opacity: 0.5;
}

.selected {
  transition: none;
}

.progress {
  position: absolute;
  top: 0;

  overflow: hidden;

  height: 100%;
}

.yetToRun {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  box-sizing: border-box;

  background-color: sidebar-background-color;
}

.queued .yetToRun {
  border: 1px solid borders-color;
  border-top-right-radius: border-radius;
  border-bottom-right-radius: border-radius;
}

.success {
  & .progress {
    background-color: success-progress-color;
  }

  &::after {
    border-color: success-progress-color;
  }
}

.failed {
  & .progress {
    background-color: error-color;
  }

  &::after {
    border-color: error-color;
  }
}

.canceled {
  & .progress {
    background-color: line-color;
  }

  &::after {
    border-color: line-color;
  }
}

.failedToStart {
  color: error-color;
}

.incomplete {
  background-color: transparent;

  &::after {
    border: 1px solid borders-color;
  }
}

.queued {
  background-color: transparent;

  &::after {
    border: 1px dashed borders-color;
  }
}

.name {
  position: absolute;
  top: 2px;

  display: flex;
  align-items: baseline;

  box-sizing: border-box;
  max-width: 100%;

  height: 15px;

  padding: 0 unit;

  border-radius: border-radius;

  font-size: font-size-smaller;
  line-height: 15px;
}

.nameInner {
  min-width: 0;
}

.unknownFinish {
  overflow: visible;

  &,
  &::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &::after {
    left: 0;

    width: var(--tc-bar-width);

    border-right: none;
  }

  &:focus,
  &[data-focus-visible-added] {
    border-top-right-radius: border-radius;
    border-bottom-right-radius: border-radius;

    &::after {
      border-right: none;
    }
  }

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: var(--tc-bar-width);

    box-sizing: border-box;

    width: var(--tc-tail-width);

    content: '';

    border-top: 1px solid borders-color;
    border-bottom: 1px solid borders-color;
    background-color: sidebar-background-color;
    mask-image: linear-gradient(to right, black, transparent);
  }

  &:focus::before,
  &[data-focus-visible-added]::before {
    border: 1px solid border-hover-color;
    border-right: none;
    mask-image: none;
  }

  & .name {
    max-width: initial;
  }

  & > .name {
    position: relative;
    top: 0;
  }
}

.queued::before {
  border-left: 1px solid borders-color;
}

.unknownStart {
  &::after {
    content: none;
  }

  &::before {
    left: 0;

    width: var(--tc-tail-width);

    border-top-style: dashed;
    border-bottom-style: dashed;
    border-left-style: dashed;
    border-top-left-radius: border-radius;
    border-bottom-left-radius: border-radius;
    background-color: content-background-color;
  }
}

@value patternSize: calc(unit / 2);

@keyframes bar-move {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateX(calc(patternSize * -1));
  }
}

.running .progress::before,
.running.progress::before {
  position: absolute;

  display: block;

  width: calc(100% + patternSize);

  height: 100%;

  content: '';
  animation: bar-move 0.45s linear infinite;

  background-repeat: repeat;
  background-position: left;
  background-size: patternSize patternSize;
}

.success .progress::before,
.success.progress::before {
  background-color: success-background-color;
  background-image:
    linear-gradient(
      135deg,
      icon-success-color,
      icon-success-color 7.5%,
      transparent 7.5%,
      transparent 42.5%,
      icon-success-color 42.5%,
      icon-success-color 57.5%,
      transparent 57.5%,
      transparent 92.5%,
      icon-success-color 92.5%,
      icon-success-color
    );
}

.failed .progress::before,
.failed.progress::before {
  background-color: icon-error-color;
  background-image:
    linear-gradient(
      45deg,
      error-background-color,
      error-background-color 7.5%,
      transparent 7.5%,
      transparent 42.5%,
      error-background-color 42.5%,
      error-background-color 57.5%,
      transparent 57.5%,
      transparent 92.5%,
      error-background-color 92.5%,
      error-background-color
    );
}

.buildsPopup {
  max-height: 45vh;
}

.buildInList {
  max-width: calc(unit * 58);
  margin: 0;

  transition: background-color fast-ease;

  &:hover {
    transition: none;

    background-color: hover-background-color;
  }

  &:not(:first-child) {
    border-top: 1px solid line-color;
  }
}

.nameHidden {
  composes: visuallyHidden;
}

.failed .progress > .name,
.failed.progress > .name {
  color: var(--ring-white-text-color);
}

.success .name::before {
  background-color: success-background-color;
}

.failed .name::before {
  background-color: icon-error-color;
}

/* stylelint-disable-next-line selector-max-specificity  */
.running > .name::before {
  position: absolute;
  top: 1px;
  right: 0;
  bottom: -1px;
  left: 0;

  content: '';

  opacity: 0.6;
}

.nameVisible {
  position: relative;
}

.gap {
  position: absolute;
  top: calc(unit * -1);
  bottom: 0;

  width: 18px;

  color: sidebar-background-color;

  background:
    linear-gradient(135deg, common-gradient) -9px -9px,
    linear-gradient(45deg, common-gradient) 9px 9px,
    linear-gradient(-135deg, common-gradient) 9px 0,
    linear-gradient(-45deg, common-gradient) 9px 0;
  background-size: 18px 18px;

  @value common-gradient: transparent 75%, line-color 75%, currentColor 79%;
}

.previewGap {
  top: 1px;
  bottom: 1px;

  color: content-background-color;
}

.brush {
  fill: content-background-color;
}

.brushBorder {
  position: absolute;
  top: 0;
  bottom: 0;

  pointer-events: none;

  box-shadow: 0 0 0 1px border-hover-color inset;
}

.previewBarSuccess {
  fill: success-progress-color;
}

.previewBarFailed {
  fill: error-color;
}

.previewBarCanceled {
  fill: icon-secondary-color;
}

.previewBarQueued {
  fill: line-color;
}

.previewBarFocused {
  fill: main-color;
}

.previewBarFade {
  fill-opacity: 0.5;
}

.queueInfo {
  font-size: font-size;
  line-height: calc(unit * 3);
}

.started {
  display: inline-block;

  margin-left: unit;

  color: secondary-color;

  font-size: font-size-smaller;
}

.hourglass {
  height: auto;

  white-space: nowrap;

  line-height: normal;

  &:not(:hover) {
    color: text-color;
  }
}

.statusIcon {
  margin: -2px 2px 0 -2px;

  line-height: normal;
}

.statusIcon.statusIcon svg {
  vertical-align: -4px;
}

.hourglassIcon {
  composes: statusIcon;
}

.canceledIcon {
  composes: statusIcon;
}

.failedToStartIcon {
  composes: statusIcon;
}

.name .hourglassIcon {
  color: icon-color;
}

.name .canceledIcon {
  color: icon-secondary-color;
}

.name .failedToStartIcon {
  color: icon-error-color;
}

.progress .failedToStartIcon {
  color: var(--ring-white-text-color);
}

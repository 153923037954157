@value unit, line-color, secondary-color, font-size-smaller, resetButton from '../../../../../../../ring-globals.css';

.tableHeader {
  display: flex;
  flex-direction: row;

  margin-top: calc(unit * 2);
  padding-bottom: unit;

  border-bottom: 1px solid line-color;
}

.columnTitle {
  composes: resetButton;

  cursor: pointer;
}

.statusCol {
  flex: 0 0 168px;
}

.nameCol {
  display: flex;

  flex: 1 1 auto;
}

.durationCol {
  flex: 0 0 136px;

  text-align: right;
}

.runOrderCol {
  flex: 0 0 92px;

  padding-right: calc(unit * 4);

  text-align: right;
}

.statusCol,
.nameCol,
.durationCol {
  padding-right: calc(unit * 2);
}

.statusCol,
.nameCol,
.durationCol,
.runOrderCol {
  box-sizing: border-box;

  color: secondary-color;

  font-size: font-size-smaller;
}

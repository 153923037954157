@value unit, secondary-color, main-color, line-color from '../../ring-globals.css';
@value margin: calc(unit * 4);

.button {
  position: relative;

  margin-top: unit;

  padding: 0;

  color: main-color;
}

@value unit, font, text-color, secondary-color, error-color, success-color, warning-color from '../../../ring-globals.css';
@value dotsAnimation: dots 2s linear infinite;

.link {
  composes: font;

  min-width: 0;
}

.success {
  color: success-color;
}

.failure {
  color: error-color;
}

.canceled {
  color: secondary-color;
}

.warning {
  color: warning-color;
}

.animated::after {
  width: calc(unit * 1.5);

  content: '';
  animation: dotsAnimation;
}

@keyframes dots {
  0% {
    content: '';
  }

  25% {
    content: '.';
  }

  50% {
    content: '..';
  }

  75% {
    content: '...';
  }

  100% {
    content: '...';
  }
}

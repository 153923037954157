@value contentWidth, unit, error-color, success-color, font-size, font-size-smaller, line-height, text-color from '../../../../ring-globals.css';

.header {
  max-width: contentWidth;
  margin-bottom: calc(unit * 3);
}

.heading.heading {
  margin: 0;
}

.title.error {
  color: error-color;
}

.title.success {
  color: success-color;
}

.star {
  height: calc(unit * 3);
  margin-left: -2px;

  vertical-align: 3px;
}

.links {
  display: flex;
  align-items: baseline;
}

.linksSequenceLoader {
  display: flex;
  flex-direction: row-reverse;

  & > * {
    margin-right: unit;
  }

  & > *:first-child {
    margin-right: 0;
  }
}

.breadcrumbs {
  flex-grow: 1;
}

.breadcrumbsLink {
  font-size: font-size-smaller;
  line-height: 24px;
}

.subHeader {
  display: flex;

  align-items: baseline;

  width: 100%;
}

.description {
  flex: 1 0 auto;
}

.statusIcon {
  margin-right: calc(unit / 2);
}

.queuedIcon {
  color: text-color;
}

.pesronalQueuedIcon {
  & :global(.secondary.secondary) {
    fill: text-color;
  }
}

.branch {
  box-sizing: border-box;
  max-width: 40%;
  height: 24px;

  line-height: 24px;
}

.actions,
.branch,
.investigation {
  margin-right: calc(unit * 1.5);

  font-size: font-size-smaller;
  line-height: 24px;
}

.headerInfo {
  margin-bottom: unit;

  padding-right: calc(unit * 2);
}

.statusChangeComment {
  font-size: font-size;
  line-height: line-height;
}

.main {
  display: flex;
}

.leftColumn {
  overflow: hidden;
  flex: 1;

  margin-bottom: -1px;

  margin-left: calc(unit * -4);

  padding: 0 calc(unit * 2) 1px calc(unit * 4);
}

.rightColumn {
  margin-left: auto;

  padding-top: calc(unit * 2);
  padding-left: calc(unit * 2);

  line-height: 0;
}

.queued {
  font-weight: normal;
}

@value unit, font-size, font-size-smaller, secondary-color, icon-secondary-color, text-color, hover-background-color from '../../../../../ring-globals.css';

.container {
  margin: 0 calc(-4 * unit);
  padding: 0 calc(4 * unit);
}

.cloud-image {
  display: flex;
  align-items: baseline;
  flex-direction: row;

  margin: 0 calc(-4 * unit);
  padding: 0 calc(4 * unit);

  line-height: calc(4 * unit);
}

.cloud-image:hover {
  background-color: hover-background-color;
}

.expandable {
  cursor: pointer;
}

.expand-handle {
  margin-right: calc(unit / 2);
  margin-left: calc(-2.25 * unit);

  color: icon-secondary-color;
}

.link,
.name {
  font-size: font-size;
  font-weight: bold;
}

.name {
  color: text-color;
}

.counter {
  margin-left: calc(unit);

  color: secondary-color;

  font-size: font-size-smaller;
}

.actions {
  margin-left: auto;
}

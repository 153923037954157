@value text-color, secondary-color, error-color, success-color, warning-color, line-height, unit from '../../../ring-globals.css';
@value dotsAnimation, dots from '../BuildStatusLink/BuildStatusLink.css';

.text {
  line-height: line-height;

  &.majorInfo {
    color: text-color;
  }

  &.minorInfo {
    color: secondary-color;
  }

  &.error {
    color: error-color;
  }

  &.success {
    color: success-color;
  }

  &.warning {
    color: warning-color;
  }
}

.animated .animated::after {
  content: '';
  animation: dotsAnimation;
}

.showButton {
  margin-left: calc(unit / 4);
}

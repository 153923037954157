@value unit from '../../../../../ring-globals.css';

.container {
  position: absolute;

  top: var(--absolute-child-top);
  bottom: 0;
  left: calc(unit * -6);

  padding: calc(unit / 2) 0 0 unit;
  grid-column-start: start;
  grid-row: var(--row) / span 3;
}

.oneLine {
  top: calc(var(--absolute-child-top) - 2px);
}

.hoverableWrapper:not(:hover) .hideableCell {
  opacity: 0;
}

.checkbox[data-focus-visible-added] + .hideableCell {
  opacity: 1;
}

@value secondary-color from '../../../../../../../../ring-globals.css';
@value middle-ellipsis from '../../../../../../../common/MiddleEllipsis/MiddleEllipsis.css';

.fixedTest {
  color: secondary-color;

  &,
  & .middle-ellipsis {
    text-decoration: line-through;
  }
}

.newTest {
  font-weight: bold;
}

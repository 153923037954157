@value unit, secondary-color, error-color from '../../../ring-globals.css';

.group {
  display: flex;
  flex-direction: column;

  height: calc(100% - 4 * unit);
  margin-top: 0;
  margin-bottom: calc(2 * unit);
}

.expandable {
  margin: calc(2 * unit) 0 0 0;
}

.header.header {
  margin: 0 calc(-4 * unit) 0 calc(-5 * unit);
}

.heading {
  color: error-color;
}

.subheading {
  color: secondary-color;
}

.content.content {
  flex: 1 0;

  padding-top: 0;
  padding-bottom: 0;
}

.container {
  padding: 0 calc(unit * 2) 0 calc(7 * unit);
}

@value unit, font-size-smaller, icon-secondary-color, hover-background-color, line-color from '../../ring-globals.css';

.message {
  max-width: 320px;
}

.wrapper {
  width: 100%;
  max-width: 100%;
  margin: 0 0 4px;
}

.wrapper.wrapper {
  margin-right: 0;
}

.description {
  margin: calc(unit / 4) 0 0;
}

.content {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.text {
  margin-right: calc(unit * 2);
}

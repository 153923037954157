@value unit from '../../../../../../ring-globals.css';

.testScope,
.testScopeRow {
  display: contents;
}

.testScope::before {
  right: calc(unit * -4);
  left: calc(unit * -4);
  grid-column: start / end;
  grid-row: var(--row) / span 1;
}

.nameCol {
  overflow: hidden;
  grid-column-start: name-start;
  grid-row-start: var(--row);
}

.durationCol {
  grid-column-start: duration-start;
  grid-row-start: var(--row);

  text-align: right;
}

.countCol {
  grid-column-start: count-start;
  grid-row-start: var(--row);

  text-align: right;
}

.nameCol,
.durationCol,
.countCol {
  margin: 0;
  padding: 0;

  line-height: calc(unit * 4);
}

@value unit, line-height, ellipsis, popup-shadow-color, line-color, secondary-color, icon-secondary-color, main-color, text-color, link-color, link-hover-color, font-smaller, font-size, icon-secondary-color from '../../../../../../ring-globals.css';
@value focusableWrapper from '../../../../../common/ListItem/ListItem.css';

.panel {
  z-index: 1;

  margin: 1px calc((unit * -4)) 0;
  padding: 0 calc((unit * 4)) 0;

  box-shadow: 0 0 0 1px line-color, 0 2px 8px popup-shadow-color;
}

.button {
  composes: focusableWrapper;
}

.item {
  composes: focusableWrapper;

  position: relative;

  display: block;

  margin: 1px calc(unit * -4) 0;

  line-height: 32px;
}

.row {
  display: flex;
  align-items: baseline;
  flex-direction: row;

  max-height: calc(unit * 4);

  padding: 0;

  & > * {
    margin-top: 0;
  }
}

.arrow {
  position: absolute;

  top: 8px;
  right: calc(unit * -4 + 10px);
  left: auto;

  padding: 0;

  transition: opacity ease;

  pointer-events: none;

  opacity: 0;
  color: icon-secondary-color;

  line-height: normal;

  @nest .button:hover &, .button:focus &, .button[data-focus-visible-added] & {
    transition: none;

    opacity: 1;
  }

  @nest .button:active & {
    transition: none;

    opacity: 1;
    color: main-color;
  }
}

.attachment {
  color: icon-secondary-color;
}

.flakyLabel,
.attachment {
  margin-left: unit;
  padding: 0;
}

.testMute,
.testInvestigation {
  flex: 0 0 auto;

  padding: 0;
}

.testMuteIcon,
.testInvestigationIcon {
  margin-right: calc(unit / 2);
}

.row .checkbox {
  padding: 0;

  white-space: nowrap;
}

.statusCol {
  flex: 0 0 100px;
}

.durationCol {
  flex: 0 0 100px;

  text-align: right;
}

.buildCol {
  display: flex;
  overflow: hidden;
  align-items: center;
  flex: 1 1 auto;
}

.changesCol,
.agentCol {
  flex: 0 0 130px;
}

.buildStartDateCol {
  flex: 0 0 110px;

  padding: 0;

  text-align: right;
  white-space: nowrap;
}

.showInBuildLogCol {
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;

  width: calc(unit * 4);
  padding: 0;
}

.showInBuildLogIcon {
  margin: 0 calc(unit * -1);
}

.statusCol,
.durationCol,
.buildCol,
.changesCol,
.agentCol {
  overflow: hidden;

  margin-right: calc(unit * 2);
  padding: 0;
}

.name {
  composes: ellipsis;

  padding: 0;
}

.testSuiteName,
.testPackageName {
  composes: font-smaller;

  margin-right: calc(unit / 2);

  color: secondary-color;

  &:last-child {
    margin-right: 0;
  }
}

.testNameWithParameters {
  margin-right: calc(unit / 2);

  &:last-child {
    margin-right: 0;
  }
}

.fixedTest .testName,
.fixedTest .testClassName,
.fixedTest .testNameWithParameters {
  text-decoration: line-through;

  color: secondary-color;
}

.withInteractiveLinks .testClassName {
  color: link-color;
}

.withInteractiveLinks .testSuiteName,
.withInteractiveLinks .testPackageName,
.withInteractiveLinks .testClassName {
  &:hover,
  &:focus,
  &[data-focus-visible-added] {
    color: link-hover-color;
  }
}

.buildNumber > a {
  color: text-color;

  font-size: font-size;
}

.separator {
  flex-shrink: 0;

  color: secondary-color;
}

.buildBranch,
.buildPath,
.buildNumber,
.buildStatus {
  margin-right: unit;

  line-height: line-height;

  &:last-child {
    margin-right: 0;
  }
}

.buildCol .buildPath {
  overflow: visible;

  margin-right: 0;
}

.details {
  padding: calc(unit * 2) 0;
}

.actions {
  width: unset;
  padding: 0;
}

@value unit from '../../ring-globals.css';

.experimentalUiOptout {
  min-width: calc(unit * 34);
  max-width: calc(unit * 59);
}

.caption.caption {
  margin-right: 0;
}

.link.link {
  color: #70b1e6;
}

.optoutButton {
  margin-right: calc(unit * -2);
}

@value unit, contentWidth from '../../../ring-globals.css';

.page {
  max-width: contentWidth;
}

.heading.heading {
  margin-bottom: calc(2 * unit);
}

.licenses-text {
  margin: calc(2 * unit) 0;
}

.agents {
  composes: container from '../AgentsGroup/AgentsGroup.css';
}

@value unit, secondary-color, font-size-smaller from '../../../../../../ring-globals.css';

.buttons {
  margin-bottom: calc(unit * 2);
}

.button,
.investigationMuteButtons {
  margin-right: unit;
}

.withOverlay {
  cursor: wait;

  opacity: 0.3;

  & > * {
    pointer-events: none;
  }
}

.actions {
  margin-top: calc(unit * 2);
}

.selectAll {
  height: 24px;
}

.selectAllCount {
  margin-left: calc(unit / 2);

  color: secondary-color;

  font-size: font-size-smaller;
}

@value unit from '../../../../../ring-globals.css';

.list {
  margin-bottom: calc(unit * 2);
}

.withOverlay {
  cursor: wait;

  opacity: 0.3;

  & > * {
    pointer-events: none;
  }
}

.content {
  margin-bottom: unit;

  padding: 0;
}

.wrapper .heading {
  margin-bottom: unit;
}

@value unit, borders-color, secondary-color, icon-disabled-color, font-smaller from '../../../ring-globals.css';

.buttonGroup.invisible {
  display: flex;
  visibility: hidden;
  overflow: hidden;

  width: 0;
  margin: 0;
}

.disabledReasonPopup {
  padding: calc(unit * 1.5) calc(unit * 2) calc(unit * 2);
}

.disabledIcon.disabledIcon {
  color: icon-disabled-color;
}

.popup {
  padding: unit calc(unit * 2);

  white-space: nowrap;
}

@value unit, link-color, ellipsis, line-height, font-size from '../../../ring-globals.css';

.wrapper {
  font-size: font-size;
  line-height: line-height;
}

.agent {
  margin-right: calc(unit / 2);
}

.agent,
.agentPool {
  composes: ellipsis;

  color: link-color;

  font-size: inherit;
  line-height: inherit;
}

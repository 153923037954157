@value unit, font, font-smaller, icon-main-color, secondary-color, icon-secondary-color, link-color from '../../../ring-globals.css';

.agentLink {
  composes: font;

  color: link-color;
}

.icon {
  flex-shrink: 0;

  width: calc(unit * 2);

  margin-right: 4px;

  text-align: center;

  color: icon-main-color;
}

.secondary {
  composes: font-smaller;

  color: secondary-color;

  & .icon {
    color: icon-secondary-color;
  }
}

.withoutLink {
  display: inline-flex;
  align-items: baseline;

  max-width: 100%;
}

.onlyText {
  overflow: hidden;
}

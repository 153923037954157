@value unit from '../../../ring-globals.css';

.filterWrapper {
  display: flex;
  flex-direction: row;
}

.changesTypeFilter,
.changesFilter {
  margin-right: calc(unit * 2);
  margin-bottom: unit;

  &:last-child {
    margin-right: 0;
  }
}

.showAllButton {
  margin-top: unit;
}

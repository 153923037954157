@value unit from '../../../../../ring-globals.css';

.grid {
  --column-width: minmax(0, max-content);
  --name-column: [start name-start] auto;
  --duration-column: [duration-start] var(--column-width);
  --count-column: [count-start] var(--column-width);

  position: relative;
  grid-template-columns: var(--name-column) var(--duration-column) var(--count-column) [end];
  grid-column-gap: calc(unit * 2);
}

.grid.withOverlay::before {
  position: absolute;
  top: var(--absolute-child-top);
  right: 0;
  bottom: 0;
  left: 0;

  grid-column: start / end;
  grid-row: 2 / auto;

  content: '';

  cursor: wait;

  background-color: rgba(255, 255, 255, 0.7);
}

@value unit, font-smaller-lower from '../../ring-globals.css';

.message {
  min-width: calc(unit * 29);
  margin-top: calc(unit / 2);

  & a:hover {
    text-decoration: none;

    color: var(--ring-active-text-color);
  }
}

.description {
  composes: font-smaller-lower;

  & li {
    margin: 0;
  }
}

@value unit, icon-secondary-color, hover-background-color, main-color, line-color, popup-shadow-color from '../../../../ring-globals.css';

.node {
  min-height: 24px;

  line-height: 24px;
}

.leaf.expanded {
  margin: 0 calc((unit * -2));
  padding: 0 calc((unit * 2));

  box-shadow: 0 0 0 1px line-color, 0 2px 8px popup-shadow-color;
}

.leftArrow {
  margin-right: calc(unit / 2);
  padding: 0;

  cursor: pointer;

  color: icon-secondary-color;
}

.leftArrow.leftArrow:global(.focus-visible) {
  box-shadow: none;
}

.label {
  display: flex;
  overflow: hidden;
  align-items: baseline;

  user-select: text;
  white-space: nowrap;
}

.item {
  position: relative;

  margin: 0 calc(unit * -2);
  padding: 0 calc(unit * 2);

  &.interactive {
    cursor: pointer;
  }
}

.background {
  position: absolute;
  z-index: -2;
  top: -1px;
  right: 0;
  bottom: -1px;
  left: 0;

  width: 100%;

  opacity: 0;
  background-color: hover-background-color;
}

.node:focus {
  outline: none;
}

.item:hover .background {
  opacity: 1;
}

/* stylelint-disable-next-line selector-max-specificity */
.leftArrow:focus + .background,
.node:focus > .item .background {
  opacity: 1;
  outline: none;
  box-shadow: inset 2px 0 main-color;
}

.nodeClickable {
  overflow: hidden;
  flex: 1;
}

.rightArrow.rightArrow {
  top: calc(unit / 2);

  @nest .item:hover &,
  .node:focus > .item & {
    transition: none;

    opacity: 1;
  }

  @nest .node:focus > .item & {
    transition: none;

    opacity: 1;
    color: main-color;
  }
}

.labelChild {
  display: contents;
}

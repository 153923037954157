@value unit, line-color, secondary-color, font-size-smaller, resetButton from '../../../../../../ring-globals.css';

.tableHeader {
  position: relative;

  display: contents;
}

.tableHeader::before {
  position: absolute;
  right: 0;
  bottom: var(--absolute-child-top);
  left: 0;

  padding-bottom: unit;

  content: '';

  border-bottom: 1px solid line-color;

  grid-column: start / end;
  grid-row: var(--row) / span 1;
}

.columnTitle {
  composes: resetButton;

  cursor: pointer;
}

.nameCol {
  display: flex;

  grid-column-start: name-start;
  grid-row-start: var(--row);
}

.durationCol {
  grid-column-start: duration-start;
  grid-row-start: var(--row);

  text-align: right;
}

.countCol {
  grid-column-start: count-start;
  grid-row-start: var(--row);

  text-align: right;
}

.nameCol,
.durationCol,
.countCol {
  padding-bottom: unit;

  color: secondary-color;

  font-size: font-size-smaller;
}

@value unit, font-larger, font-size-smaller, secondary-color, borders-color, navigation-background-color from '../../../ring-globals.css';

.overviewPlaceholder {
  position: relative;
}

.arrow {
  position: absolute;

  display: block;
}

.tip {
  position: absolute;
}

.tipLeft {
  top: calc(unit * 24);

  max-width: calc(unit * 21);

  text-align: left;

  & .arrow {
    top: calc(unit * -6);

    width: calc(unit * 7 + 1px);
    height: 45px;
  }
}

.tipRight {
  top: calc(unit * 6);
  right: 0;

  max-width: 190px;

  text-align: right;

  & .arrow {
    top: calc(unit * -7 + unit / 2);
    left: calc(unit * 15);

    width: calc(unit * 7);
    height: calc(unit * 8);
  }
}

.title {
  composes: font-larger;

  margin-top: calc(unit * 2);
}

.description {
  margin-top: unit;
}

.button,
.link {
  margin-bottom: unit;
}

.button {
  &:hover {
    text-decoration: none;

    color: white;
  }
}

.link {
  display: inline;
}

.links-container {
  display: inline-flex;
  flex-direction: column;

  margin-top: unit;
}

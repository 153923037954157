@value unit, font-size-smaller, line-height-lowest from '../../ring-globals.css';

.caption {
  box-sizing: border-box;
  max-width: 100%;
  margin-left: calc(unit * -0.5);
}

.caption.caption {
  margin-right: calc(unit * 2);
}

.container {
  display: flex;
  align-items: baseline;

  line-height: line-height-lowest;
}

.icon {
  margin-right: calc(unit * 1.5);
  margin-left: calc(unit / 2);
}

.title {
  margin: calc(unit / 4) 0 0;

  font-weight: normal;

  line-height: line-height-lowest;
}

.description {
  margin: calc(unit / 2) 0 calc(unit * 1.5);

  color: var(--ring-text-color);

  font-size: font-size-smaller;

  line-height: line-height-lowest;
}

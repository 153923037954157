@value unit, ellipsis, hover-background-color, line-height, icon-secondary-color, main-color, font-size-smaller, secondary-color, line-color, popup-shadow-color from '../../../../ring-globals.css';

.open {
  &::before {
    position: absolute;

    z-index: 1;
    top: var(--absolute-child-top);
    right: calc(unit * -4);
    bottom: 0;
    left: calc(unit * -4);

    content: '';
    pointer-events: none;

    box-shadow:
      0 0 0 1px line-color,
      0 2px 8px popup-shadow-color;

    grid-column: start / end;
    grid-row: var(--row) / span 2;
  }
}

.open.showFilesMode {
  &::before {
    display: none;
  }
}

.button {
  &::before {
    position: absolute;
    top: 0;
    right: calc(unit * -4);
    bottom: 1px;
    left: calc(unit * -4);

    content: '';
  }

  grid-column: start / end;
  grid-row: var(--row) / span 1;
}

.button:focus,
.button[data-focus-visible-added] {
  outline: none;

  &::before {
    box-shadow: inset 2px 0 main-color;
  }
}

.closed:hover .button,
.closed .button:focus,
.closed .button[data-focus-visible-added] {
  @nest &::before {
    background-color: hover-background-color;
  }
}

.item,
.detailsHeading,
.row {
  display: contents;
}

.detailsWrapper {
  grid-column: start / end;
}

.arrow {
  position: absolute;

  grid-column: -2;
  grid-row: var(--row) / span 1;

  top: calc(var(--absolute-child-top) + 8px);
  right: calc(unit * -4 + 10px);
  left: auto;

  padding: 0;

  transition: opacity ease;

  pointer-events: none;

  opacity: 0;
  color: icon-secondary-color;

  line-height: normal;

  @nest .item:hover &,
  .button:focus ~ &,
  .button[data-focus-visible-added] ~ & {
    transition: none;

    opacity: 1;
  }

  @nest .button:active ~ & {
    transition: none;

    opacity: 1;
    color: main-color;
  }
}

.chartCol {
  position: relative;

  align-self: start;

  min-width: 0;
  margin-right: calc(unit * -1);
  grid-column-start: chart-start;
  grid-row-start: var(--row);

  padding-top: 6px;
}

.commentCol {
  position: relative;

  min-width: 0;
  grid-column-start: comment-start;
  grid-row-start: var(--row);
}

.commentWrapper {
  display: flex;
  overflow: hidden;

  line-height: line-height;
}

.comment {
  overflow-wrap: break-word;

  overflow: hidden;
}

.singleline {
  composes: ellipsis;

  white-space: nowrap !important;
}

.branches {
  margin-left: unit;
}

.authorAndDateCol {
  position: relative;

  display: flex;
  overflow: hidden;
  align-items: baseline;

  grid-column-start: author-date-start;
  grid-row-start: var(--row);
}

.author {
  display: inline-block;

  font-size: font-size-smaller;
}

.datetime {
  margin-left: calc(unit / 2);

  white-space: nowrap;

  color: secondary-color;

  font-size: font-size-smaller;
}

.revisionCol {
  display: flex;
  justify-content: flex-end;

  grid-column-start: revision-start;
  grid-row-start: var(--row);

  color: secondary-color;

  font-size: font-size-smaller;
}

@media (max-width: 1200px) {
  .revisionCol {
    display: none;
  }
}

.filesCol {
  display: flex;
  justify-content: flex-end;

  grid-column-start: files-start;
  grid-row-start: var(--row);
}

.actionsCol {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  grid-column-start: actions-start;
  grid-row-start: var(--row);
}

.revisionCol,
.filesCol {
  text-align: right;

  font-size: font-size-smaller;
}

.commentCol,
.authorAndDateCol,
.revisionCol,
.filesCol,
.actionsCol {
  position: relative;

  padding-top: 6px;
  padding-bottom: 6px;

  &:first-child {
    margin-left: 0;
  }
}

.changeFromSnapshotDependencyIcon,
.settingsIcon,
.personalIcon {
  height: unset;
  margin-right: calc(unit / 2);

  line-height: line-height;
}

.myPersonalIcon {
  color: main-color;
}

.multylineIcon {
  margin-left: calc(unit / 2);

  color: icon-secondary-color;
}

.externalViewer {
  margin-right: calc(unit * 2);

  line-height: 12px;
}

.actionsAnchor {
  height: unset;
  padding: 0;

  line-height: line-height;
}

.outerContainer {
  position: relative;

  overflow: hidden;

  height: calc(unit * 11);
  margin: 0 calc((unit * -4));
}

.innerContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  padding: 0 calc((unit * 4));
}

.smallPadding {
  &.open::before {
    right: calc(unit * -2);
    left: calc(unit * -2);
  }

  & .arrow {
    top: 5px;
    right: calc(unit * -4.5);
  }

  & .commentCol,
  & .authorAndDateCol,
  & .revisionCol,
  & .filesCol,
  & .actionsCol {
    padding: 0;

    padding-top: 2px;
    padding-bottom: 2px;
  }

  & .button::before {
    right: calc(unit * -2);
    left: calc(unit * -2);
  }
}
